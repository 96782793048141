<template>
 <div>
  <svg id="Laag_1" style="enable-background:new 0 0 183 183;" version="1.1" viewBox="0 0 183 183" x="0px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
   <defs>
    <linearGradient gradientTransform="matrix(1 0 0 -1 0 184)" gradientUnits="userSpaceOnUse" id="icon-menu-breakfast_00000067936141922969111060000007598113845567288712_" x1="150.7642" x2="26.0142" y1="142.2249" y2="37.5549">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <rect :fill="background" height="183" width="183"/>
   <path d="  M135.7,66.1V100h10.6v33.9h10.6V49.1C145.2,49.1,135.7,58.6,135.7,66.1z M60,78.8h-8.5V49.1H43v29.7h-8.5V49.1h-8.5v29.7  c0,8.9,7,16.3,15.9,16.8v38.3h10.6V95.6c8.9-0.5,15.9-7.9,15.9-16.8V49.1H60V78.8z M66,128.9h69.7v5H66V128.9z M100.9,111.5  c5.5,0,10,4.5,10,10h5c-0.2-8.3-7.1-14.8-15.3-14.6c-8,0.2-14.4,6.6-14.6,14.6h5C91,116,95.4,111.6,100.9,111.5L100.9,111.5z   M123.3,116.5h12.4v5h-12.5L123.3,116.5z M115,103.8l8.7-8.8l3.5,3.5l-8.7,8.7L115,103.8z M100.8,71.6L88.4,84.1l3.5,3.5l6.4-6.4V99  h5V81.2l6.4,6.4l3.5-3.5L100.8,71.6z M74.4,98.6L78,95l8.7,8.7l-3.5,3.5L74.4,98.6z M66,116.5h12.4v5H66V116.5z" id="icon-menu-breakfast" style="fill:url(#icon-menu-breakfast_00000067936141922969111060000007598113845567288712_);"/>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>